[data-component='container-isi-v2'] {
  .aaaem-isi {
    &-banner {
      border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
      padding-top: var(--emu-common-spacing-medium);
      padding-right: 20px;
      padding-bottom: var(--emu-common-spacing-medium);
      padding-left: var(--emu-common-spacing-small);

      @include mq('large') {
        padding-right: var(--emu-common-spacing-medium);
        padding-left: var(--emu-common-spacing-medium);
      }

      @include max-container;
      position: relative;

      @include mq('large') {
        justify-content: flex-start;

      }

      button {
        padding: var(--emu-common-spacing-none);

        span {
          font-size: 0;
        }

        svg {
          margin-right: var(--emu-common-spacing-none);
        }
      }

      p {
        font-size: var(--emu-common-font-sizes-captions-large);
        line-height: var(--emu-common-line-heights-narrow-jumbo);
        margin-top: var(--emu-common-spacing-none);
      }
    }

    &-container {
      padding-top: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-medium);
      padding-left: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-small);

      @include mq('large') {
        padding-top:9px;// to match the design
        padding-right: 36px;
        padding-left: 36px;
        padding-bottom: 9px;// to match the design
      }

      &__banner-content {
        color: var(--emu-common-colors-brand-black-100);

        @include mq('large') {
          width: calc(
            50% - 2px
          ); // intentional calculation to match the design closer
          padding-right: var(--emu-common-spacing-brand-xs);
        }

        b {
          @include mq('large') {
            display: inline;
            letter-spacing: -0.2px;
          }
        }

        &[data-ref='banner-content-on-open'] {
          width: 100%;
          display: block;

          b {
            @include mq('large') {
              letter-spacing: normal;
            }
          }
        }
      }

      &__button-wrapper {
        position: absolute;
        right: 11px;
        top: 15px;

        @include mq('large') {
          right: 20px;
          top: 15px;
        }
      }

      &__isi-content {
        @include max-container;
      }
    }
  }

  .isi-content {
    &__title .cmp-title__text {
      font-size: 28px;
      line-height: 128%;
      letter-spacing: -0.6px;
      font-weight: 500; // no variable for this
      margin-bottom: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        font-size: 36px;
        line-height: 122%;
        letter-spacing: -1.2px;
        margin-bottom: var(--emu-common-spacing-brand-medium);
      }
    }

    &__subtitle {
      margin-bottom: 14px;

      p {
        font-size: var(--emu-common-font-sizes-body-large);
        line-height: var(--emu-common-line-heights-narrow-xl);
        letter-spacing: -0.2px;

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-headline-medium);
          line-height: var(--emu-common-line-heights-narrow-jumbo);
          letter-spacing: -0.8px;
        }
      }
    }

    &__text {
      &--margin-adjustment {
        margin-top: 21px;

        @include mq('large') {
          margin-top: 27px;
        }
      }

      p {
        line-height: 162%;

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-body-regular);
        }

        & + p {
          margin-top: 26px;
        }

        b {
          .emphasis {
            line-height: 150%;
            display: block;
          }
        }
      }

      ul {
        margin-top: 26px;
        margin-bottom: 26px;
        padding-left: var(--emu-common-spacing-brand-xs);

        @include mq('large') {
          padding-left: 24px;
        }

        li {
          margin-top: var(--emu-common-spacing-none);
          line-height: 162%;

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-body-regular);
          }

          & + li {
            margin-top: 28px;
          }
        }
      }
    }
  }
}
