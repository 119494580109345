.img-txt-grid {
  &.img-txt-grid--reverse-in-mobile {
    .img-txt-grid__inner {
      @include mq('large', 'max-width') {
        flex-direction: column-reverse;
      }
    }
  }

  &.img-txt-grid--with-logo-and-disclaimer {
    .img-txt-grid {
      &__logo {
        position: absolute;
        right: 0;
        bottom: 0;
        bottom: 32px;
        right: 16px;
        max-width: 40%;

        @include mq('large') {
          right: 56px;
          bottom: 56px;
        }
      }

      &__disc {
        max-width: 55%; //As per figma
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include mq('large') {
      flex-direction: row;
    }

    > .container {
      @include mq('large') {
        width: 50%;
        flex: 1;
      }
    }
  }

  &__section {
    @include mq('large') {
      height: 100%;
    }

    @include aem-editor-view {
      height: auto;
    }

    &--asset {
      > div:has(> .desktop-view),
      > div:not(:has(> .tablet-mobile-view)) {
        @include mq('large') {
          height: 100%;
        }

        @include aem-editor-view {
          height: auto;
        }
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-top: var(--emu-common-spacing-large);
      padding-bottom: var(--emu-common-spacing-large);
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      position: relative;

      @include mq('large') {
        padding: var(--emu-common-spacing-brand-medium);
      }

      &-no-spacing {
        padding: var(--emu-common-spacing-none);
      }

      &-split {
        > .container:first-child {
          flex: 1;
        }

        .img-txt-grid__text {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &__title-container {
    display: flex;
    margin-bottom: 9px;

    @include mq('large') {
      margin-bottom: 23px;
      align-items: center;
    }

    .img-txt-grid__title {
      margin-bottom: var(--emu-common-spacing-none);
    }

    &--stacked {
      flex-direction: column;
      align-items: start;
    }
  }

  &__title {
    margin-bottom: 14px;

    @include mq('large') {
      margin-bottom: 30px;
      padding-right: var(--emu-common-spacing-medium);
    }
  }

  &__count {
    font-size: var(--emu-common-font-sizes-body-small);
    line-height: var(--emu-common-line-heights-narrow-xxxl);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--emu-common-sizing-medium); // as per live site
    height: var(--emu-common-sizing-medium);
    border: 0.8px solid var(--emu-common-colors-black);
    border-radius: 20px;
    margin-right: var(--emu-common-spacing-medium);
    margin-top: 7px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }
  }

  &__subtitle {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-bottom: 18px;
    }
  }

  &__text {
    margin-bottom: var(--emu-common-spacing-brand-xs);

    @include mq('large') {
      margin-bottom: 23px;
    }
  }

  &__disc,
  &__logo {
    margin-top: 50px;

    &.u-mt-auto {
      @include mq('large') {
        margin-top: var(--emu-common-spacing-brand-xs);
      }
    }
  }

  &__img {
    @include mq('large') {
      height: 100%;
    }

    @include aem-editor-view {
      height: auto;
    }

    img {
      width: 100%;
      max-height: 390px; // As per design
      object-fit: cover;

      @include mq('large') {
        min-height: 600px; // As per design
        max-height: none;
        height: 100%;
      }

      @include aem-editor-view {
        max-height: 600px; // As per design
      }
    }
  }

  &__logo {
    img {
      max-height: 37px;

      @include mq('large') {
        max-height: 54px;
      }
    }
  }

  &__content {
    padding-top: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);

    @include mq('large') {
      padding-top: var(--emu-common-spacing-brand-medium);
      padding-bottom: var(--emu-common-spacing-brand-medium);
      padding-left: var(--emu-common-spacing-brand-medium);
      padding-right: var(--emu-common-spacing-brand-medium);
    }

    &--top {
      @include mq('large') {
        padding-bottom: 48px;
      }
    }

    &--bottom {
      padding-top: 34px;

      @include mq('large') {
        padding-top: 51px;
      }
    }
  }

  &__disc {
    &-title {
      margin-bottom: 10px;
    }

    &-text {
      margin-bottom: var(--emu-common-spacing-medium);
    }

    &-cta {
      margin-bottom: 40px;

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-brand-xs);
      }
    }
  }
}
