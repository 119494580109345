.two-col-teaser {
  @include mq('large') {
    display: flex;

    // any div present directly inside this is going to have 50% width
    > div {
      flex: 1;
      width: 50%;
    }
  }

  .teaser {
    .emu-teaser.emu-teaser-v3.inline-text-overlay.inline-text-overlay.inline-text-overlay {
      .teaser-container {
        .content-container {
          padding-top: 35px;
          padding-bottom: 28px;
          padding-right: 15px;

          @include mq('x-large') {
            padding-top: 60px;
            padding-bottom: var(--emu-common-spacing-brand-medium);
            padding-right: var(--emu-common-spacing-brand-medium);
            padding-left: 61px;
          }

          .emu-teaser__title {
            @include mq('large') {
              font-size: 56px; // no variable available for this font size
              line-height: var(--emu-common-line-heights-custom-xs);
              letter-spacing: -1px;
            }
          }

          .emu-teaser__disclaimer {
            margin-top: var(--emu-common-spacing-brand-small);

            @include mq('x-large') {
              position: static;
              border-top: none;
              padding: var(--emu-common-spacing-none);
            }
          }

          .emu-teaser__action-container {
            margin-top: 31px;
          }
        }
      }
    }
  }
}
