.three-card-grid {
  display: flex;
  flex-direction: row;
  gap: var(--emu-common-spacing-brand-xs);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  flex-wrap: wrap;

  @include mq('large') {
    padding-top: 62px;
    padding-bottom: var(--emu-common-spacing-brand-medium);
    padding-left: var(--emu-common-spacing-brand-medium);
    padding-right: var(--emu-common-spacing-brand-medium);
    row-gap: 48px;
  }

  &.three-card-grid--compact {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    gap: var(--emu-common-spacing-none);

    > .container {
      @include mq('large') {
        width: 33.333%;
      }

      &:not(:nth-child(3n + 3)) {
        @include mq('large') {
          border-right: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-black);
        }
      }
    }
  }

  > .container {
    width: 100%;

    @include mq('medium') {
      width: calc(50% - var(--emu-common-spacing-brand-xs));
    }

    @include mq('large') {
      width: calc(33.333% - var(--emu-common-spacing-medium));
    }

    > div {
      width: 100%;
      height: 100%;
    }
  }
}
