[data-component='carousel-v2'] {
  &.emu-carousel {
    // increasing specificity
    &.emu-carousel {
      padding-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-brand-medium);
      }
    }

    .tns-nav {
      position: absolute;
      top: -37px;
      left: 60px;

      @include mq(large) {
        top: -45px;
        left: auto;
        right: 26px;
      }

      button {
        &,
        &.tns-nav-active {
          background-color: var(
            --emu-component-containers-carousel-indicators-dots-color-background-default-light
          );
          border-color: var(
            --emu-component-containers-carousel-indicators-dots-color-border-default-light
          );
        }

        &.js-nav-active {
          background-color: var(
            --emu-component-containers-carousel-indicators-dots-color-background-active-light
          );
          border-color: var(
            --emu-component-containers-carousel-indicators-dots-color-border-active-light
          );
        }

        &:not(:first-child) {
          margin-left: 14px;
        }
      }
    }

    .emu-carousel__content {
      &.u-center-align {
        justify-content: space-between;
      }
    }

    .tns-controls {
      .emu-carousel__action {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
        margin-top: -2px;
        opacity: 1;
        transition: all var(--emu-common-other-time-transition-base) ease-in-out;

        &:disabled {
          opacity: 0;
          pointer-events: none;
        }

        &::after {
          content: '';
          position: absolute;
          width: 18px; // to match design
          height: 18px; // to match design
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url('../../assets/images/right-arrow.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        &-prev {
          left: var(--emu-common-spacing-brand-medium);

          &::after {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }

        &-next {
          right: var(--emu-common-spacing-brand-medium);
        }

        &-icon {
          display: none;
        }
      }
    }
  }
}
