#treatments {
  .img-txt-grid {
    &--treatment,
    &--results {
      .img-txt-grid {
        &__title {
          &-container {
            @include mq('large', 'max-width') {
              margin-bottom: 14px;
            }
          }
        }

        &__text {
          @include mq('large', 'max-width') {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  .treatments__carousel {
    .emu-carousel__item-container {
      @include mq('large', 'max-width') {
        margin-right: 17px;
      }
    }
  }

  .intro-box--treatment-diaries {
    padding-bottom: var(--emu-common-spacing-brand-xs);

    .intro-box {
      &__title {
        .cmp-title__text {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-display-regular);
            line-height: var(--emu-common-line-heights-wide-small);
            letter-spacing: -1.44px;
          }
        }
      }
    }
  }

  .references {
    padding-bottom: 35px;

    @include mq('large') {
      padding-top: 54px;
    }
  }

  .treatments {
    &__carousel {
      .tns-nav {
        left: 68px;

        @include mq('large') {
          left: auto;
          top: -43px;
        }
      }

      .brand-card {
        padding-top: 40px;

        @include mq('large') {
          padding-top: var(--emu-common-spacing-xxs);
        }

        &__txt {
          @include mq('large') {
            margin-bottom: 23px;
          }
        }
      }
    }
  }
}
