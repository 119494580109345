#lower-face-and-neck {
  .lfn {
    &__main-intro {
      padding-bottom: 54px;

      @include mq('large') {
        padding-bottom: 106px;
      }

      .intro-box {
        &__title {
          @include mq('large', 'max-width') {
            margin-bottom: var(--emu-common-spacing-medium);
          }

          @include mq('large') {
            max-width: 75%;
          }
        }
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__title {
          @include mq('large', 'max-width') {
            margin-bottom: 15px;
          }
        }

        &__text {
          margin-bottom: var(--emu-common-spacing-none);
        }

        &__disc {
          margin-top: 51px;
        }
      }
    }

    &__ba-intro-box {
      padding-top: 31px;
      padding-bottom: 121px;

      @include mq('large') {
        padding-top: 54px;
        padding-bottom: 105px;
      }

      .intro-box {
        &__title {
          @include mq('large', 'max-width') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }
      }
    }

    &__ba-item {
      .ba-item {
        &__title {
          margin-bottom: 54px;
        }

        &__img {
          &-label p {
            @include mq('large') {
              padding-bottom: 9px;
            }
          }
        }

        &__disc {
          @include mq('large') {
            padding-right: var(--emu-common-spacing-brand-xs);
          }
        }
      }
    }

    &__tabbed-teaser {
      .tabbed-teaser {
        &__main .emu-teaser__action-container {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-medium);
        }

        &__main--bands {
          .emu-teaser__action-container {
            margin-top: var(--emu-common-spacing-brand-xs);

            @include mq('x-large') {
              margin-top: 42px;
            }
          }
        }

        &__disclaimer {
          letter-spacing: 0.01px;
        }

        .tabbed-teaser__disclaimer--right-align-in-mobile p {
          @include mq('x-large', 'max-width') {
            text-align: right; //alignment is different in mobile
          }
        }

        .emu-teaser {
          &__title,
          &__description,
          &__disclaimer p {
            @include mq('x-large') {
              color: var(--emu-common-colors-brand-black-100);
            }
          }
        }
      }
    }

    &__references {
      padding-bottom: 35px;
    }
  }
}
