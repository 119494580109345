#savings {
  .savings {
    &__hero-teaser {
      .emu-teaser {
        &__description {
          @include mq('large') {
            max-width: 417px; // to match the design
          }
        }
      }
    }

    // hiw title
    &__work-section {
      padding-bottom: 92px;
      padding-top: 23px;

      @include mq('large') {
        padding-bottom: 68px;
        padding-top: 45px;
      }
    }

    &__reward-section {
      .img-txt-grid {
        &__title {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: 31px;
          }
        }
      }
    }

    &__save-teaser,
    &__over-time-teaser {
      .content-container {
        padding-right: 10px;
        padding-top: 23px;

        @include mq('large') {
          padding-top: 45px;
        }
      }

      .emu-teaser {
        &__title {
          margin-bottom: 15px;

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-display-regular);
            line-height: var(--emu-common-line-heights-narrow-small);
            margin-bottom: 28px;
            letter-spacing: -1.44px;
          }
        }

        &__description {
          max-width: 359px; //to match the design

          @include mq('large') {
            max-width: 424px; //to match the design
          }

          p {
            @include mq('large') {
              font-size: var(--emu-common-font-sizes-body-regular);
              line-height: var(--emu-common-line-heights-wide-jumbo);
            }
          }
        }

        &__action-container {
          @include mq('large') {
            margin-top: 26px;
          }
        }

        &__disclaimer {
          max-width: 603px;

          p {
            font-size: var(--emu-common-font-sizes-captions-large);
          }
        }
      }
    }

    &__promo {
      padding-top: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-top: 55px;
      }

      .promo {
        &__content {
          padding-top: 56px;
          padding-bottom: 40px;

          @include mq('large') {
            padding-left: 56px;
          }
        }

        &__img-container {
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            padding-top: 40px;
            padding-right: 53px;
          }

          img {
            margin: auto;
          }
        }

        &__title {
          margin-bottom: var(--emu-common-spacing-large);

          @include mq('large') {
            max-width: 512px; //to match the design
            margin-bottom: 28px;
          }

          .cmp-title__text {
            margin-bottom: var(--emu-common-spacing-none);
            letter-spacing: -0.6px;

            @include mq('large') {
              letter-spacing: -0.9px;
            }
          }
        }

        &__txt {
          letter-spacing: -1.1px;
          margin-bottom: 39px;
        }
      }

      &-block {
        @include mq('large') {
          display: flex;

          > .container {
            flex: 1;
          }
        }
      }

      &-logo {
        margin-bottom: 28px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-brand-xs);
        }
      }

      &-links {
        display: flex;
        flex-direction: row;
      }

      &-link--apple {
        margin-right: 13px;

        @include mq('large') {
          margin-right: var(--emu-common-spacing-brand-xs);
        }

        img {
          @include mq('large') {
            width: 174px; // to match the design
          }
        }
      }

      &-link--gp {
        img {
          @include mq('large') {
            width: 196px; // to match the design
          }
        }
      }
    }

    &__licensed-provider {
      padding-top: var(--emu-common-spacing-brand-xs);
      padding-bottom: 31px;

      @include mq('large') {
        padding-top: 47px;
        padding-bottom: 15px;
      }

      .intro-box {
        &__title {
          @include mq('large') {
            width: 630px; // to match the design
            margin-bottom: 29px;
          }
        }

        &__txt {
          p {
            font-size: var(--emu-common-font-sizes-body-regular);
            line-height: var(--emu-common-line-heights-wide-jumbo);
          }
        }
      }
    }

    &__product-image-container {
      margin-bottom: -21px; // to match the ISI

      @include mq('large') {
        margin-bottom: -24px; // to match the ISI
      }

      img {
        width: 100%;
      }
    }
  }
}
