:root {
  /**
  * @theme botox-cosmetic-consumer-site-theme
  * @category common tokens
  */
  --emu-common-colors-white: #FFFFFF;
  --emu-common-colors-black: #000000;
  --emu-common-colors-transparent: rgba(0,0,0,0);
  --emu-common-colors-dark-grey-100: #E8E8E8;
  --emu-common-colors-light-grey-100: #FAFAFA;
  --emu-common-colors-light-grey-200: #B2B2B2;
  --emu-common-colors-primary-100: #F0F0FF;
  --emu-common-colors-primary-200: #E1E1FE;
  --emu-common-colors-primary-300: #CECEFF;
  --emu-common-colors-primary-400: #B5B5FD;
  --emu-common-colors-primary-500: #9898FC;
  --emu-common-colors-primary-600: #989BF9;
  --emu-common-colors-primary-700: #ccccfe;
  --emu-common-colors-primary-800: #A3A4F7;
  --emu-common-colors-secondary-100: #FBFAF8;
  --emu-common-colors-secondary-200: #F7F5F1;
  --emu-common-colors-secondary-300: #F2F0EC;
  --emu-common-colors-secondary-400: #E9E5DE;
  --emu-common-colors-secondary-500: #FCF4E8;
  --emu-common-colors-secondary-600: #ECE9E5;
  --emu-common-colors-secondary-700: #FFF4E9;
  --emu-common-colors-tertiary-500: #FFFCB8;
  --emu-common-colors-brand-grey-100: #DEDAD7;
  --emu-common-colors-brand-grey-200: #cccccc;
  --emu-common-colors-brand-grey-300: #b3b3b3;
  --emu-common-colors-brand-grey-400: #b3b0ae;
  --emu-common-colors-brand-grey-500: #787676;
  --emu-common-colors-brand-grey-600: #666666;
  --emu-common-colors-brand-grey-700: #aaaaaa;
  --emu-common-colors-brand-grey-800: #EDE9E5;
  --emu-common-colors-brand-black-100: #020202;
  --emu-common-font-weight-600: 600;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-families-sans: 'Graphik',sans-serif;
  --emu-common-font-families-serif: 'Teodor',sans-serif;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-width-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-font-sizes-display-xlarge: 136px;
  --emu-common-font-sizes-display-large: 120px;
  --emu-common-font-sizes-display-medium: 80px;
  --emu-common-font-sizes-display-regular: 72px;
  --emu-common-font-sizes-display-small: 64px;
  --emu-common-font-sizes-display-xsmall: 48px;
  --emu-common-font-sizes-headline-xlarge: 40px;
  --emu-common-font-sizes-headline-large: 32px;
  --emu-common-font-sizes-headline-medium: 24px;
  --emu-common-font-sizes-body-large: 20px;
  --emu-common-font-sizes-body-medium: 18px;
  --emu-common-font-sizes-body-regular: 16px;
  --emu-common-font-sizes-body-small: 14px;
  --emu-common-font-sizes-captions-large: 12px;
  --emu-common-font-sizes-captions-medium: 10px;
  --emu-common-font-sizes-captions-regular: 8px;
  --emu-common-font-sizes-narrow-medium: 24px;
  --emu-common-font-sizes-narrow-large: 32px;
  --emu-common-font-sizes-narrow-xl: 40px;
  --emu-common-font-sizes-narrow-xxl: 48px;
  --emu-common-font-sizes-narrow-xxxl: 64px;
  --emu-common-font-sizes-wide-medium: 32px;
  --emu-common-font-sizes-wide-large: 32px;
  --emu-common-font-sizes-wide-xl: 48px;
  --emu-common-font-sizes-wide-xxl: 72px;
  --emu-common-font-sizes-wide-xxxl: 80px;
  --emu-common-line-heights-custom-xs: 88%;
  --emu-common-line-heights-custom-regular: 100%;
  --emu-common-line-heights-custom-xxxl: 142%;
  --emu-common-line-heights-narrow-small: 95%;
  --emu-common-line-heights-narrow-medium: 105%;
  --emu-common-line-heights-narrow-large: 110%;
  --emu-common-line-heights-narrow-xl: 120%;
  --emu-common-line-heights-narrow-xxl: 140%;
  --emu-common-line-heights-narrow-xxxl: 145%;
  --emu-common-line-heights-narrow-jumbo: 150%;
  --emu-common-line-heights-wide-small: var(--emu-common-line-heights-narrow-small);
  --emu-common-line-heights-wide-medium: var(--emu-common-line-heights-narrow-medium);
  --emu-common-line-heights-wide-large: var(--emu-common-line-heights-narrow-large);
  --emu-common-line-heights-wide-xl: var(--emu-common-line-heights-narrow-xl);
  --emu-common-line-heights-wide-xxl: var(--emu-common-line-heights-narrow-xxl);
  --emu-common-line-heights-wide-xxxl: var(--emu-common-line-heights-narrow-xxxl);
  --emu-common-line-heights-wide-jumbo: var(--emu-common-line-heights-narrow-jumbo);
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-brand-xs: 24px;
  --emu-common-spacing-brand-small: 40px;
  --emu-common-spacing-brand-medium: 56px;
  --emu-common-spacing-brand-large: 104px;

  /**
  * @theme botox-cosmetic-consumer-site-theme
  * @category semantic tokens
  */
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-surface-modal-backdrop: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-common-colors-primary-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-common-colors-primary-200);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-common-colors-primary-100);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-common-colors-primary-500);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-primary-700);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-common-colors-brand-grey-600);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-common-colors-brand-grey-300);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-common-colors-brand-grey-500);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-secondary-400);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-font-sizes-display-xlarge: var(--emu-common-font-sizes-display-xlarge);
  --emu-semantic-font-sizes-display-large: var(--emu-common-font-sizes-display-large);
  --emu-semantic-font-sizes-display-medium: var(--emu-common-font-sizes-display-medium);
  --emu-semantic-font-sizes-display-regular: var(--emu-common-font-sizes-display-regular);
  --emu-semantic-font-sizes-display-small: var(--emu-common-font-sizes-display-small);
  --emu-semantic-font-sizes-display-xsmall: var(--emu-common-font-sizes-display-xsmall);
  --emu-semantic-font-sizes-headline-xlarge: var(--emu-common-font-sizes-headline-xlarge);
  --emu-semantic-font-sizes-headline-large: var(--emu-common-font-sizes-headline-large);
  --emu-semantic-font-sizes-headline-medium: var(--emu-common-font-sizes-headline-medium);
  --emu-semantic-font-sizes-body-large: var(--emu-common-font-sizes-body-large);
  --emu-semantic-font-sizes-body-medium: var(--emu-common-font-sizes-body-medium);
  --emu-semantic-font-sizes-body-regular: var(--emu-common-font-sizes-body-regular);
  --emu-semantic-font-sizes-body-small: var(--emu-common-font-sizes-body-small);
  --emu-semantic-font-sizes-captions-large: var(--emu-common-font-sizes-captions-large);
  --emu-semantic-font-sizes-captions-medium: var(--emu-common-font-sizes-captions-medium);
  --emu-semantic-font-sizes-captions-regular: var(--emu-common-font-sizes-captions-regular);
  --emu-semantic-font-sizes-narrow-medium: var(--emu-common-font-sizes-narrow-medium);
  --emu-semantic-font-sizes-narrow-large: var(--emu-common-font-sizes-narrow-large);
  --emu-semantic-font-sizes-narrow-xl: var(--emu-common-font-sizes-narrow-xl);
  --emu-semantic-font-sizes-narrow-xxl: var(--emu-common-font-sizes-narrow-xxl);
  --emu-semantic-font-sizes-narrow-xxxl: var(--emu-common-font-sizes-narrow-xxxl);
  --emu-semantic-font-sizes-wide-medium: var(--emu-common-font-sizes-wide-medium);
  --emu-semantic-font-sizes-wide-large: var(--emu-common-font-sizes-wide-large);
  --emu-semantic-font-sizes-wide-xl: var(--emu-common-font-sizes-wide-xl);
  --emu-semantic-font-sizes-wide-xxl: var(--emu-common-font-sizes-wide-xxl);
  --emu-semantic-font-sizes-wide-xxxl: var(--emu-common-font-sizes-wide-xxxl);
  --emu-semantic-line-heights-custom-xs: var(--emu-common-line-heights-custom-xs);
  --emu-semantic-line-heights-custom-regular: var(--emu-common-line-heights-custom-regular);
  --emu-semantic-line-heights-custom-xxxl: var(--emu-common-line-heights-custom-xxxl);
  --emu-semantic-line-heights-narrow-small: var(--emu-common-line-heights-narrow-small);
  --emu-semantic-line-heights-narrow-medium: var(--emu-common-line-heights-narrow-medium);
  --emu-semantic-line-heights-narrow-large: var(--emu-common-line-heights-narrow-large);
  --emu-semantic-line-heights-narrow-xl: var(--emu-common-line-heights-narrow-xl);
  --emu-semantic-line-heights-narrow-xxl: var(--emu-common-line-heights-narrow-xxl);
  --emu-semantic-line-heights-narrow-xxxl: var(--emu-common-line-heights-narrow-xxxl);
  --emu-semantic-line-heights-narrow-jumbo: var(--emu-common-line-heights-narrow-jumbo);
  --emu-semantic-line-heights-wide-small: var(--emu-common-line-heights-wide-small);
  --emu-semantic-line-heights-wide-medium: var(--emu-common-line-heights-wide-medium);
  --emu-semantic-line-heights-wide-large: var(--emu-common-line-heights-wide-large);
  --emu-semantic-line-heights-wide-xl: var(--emu-common-line-heights-wide-xl);
  --emu-semantic-line-heights-wide-xxl: var(--emu-common-line-heights-wide-xxl);
  --emu-semantic-line-heights-wide-xxxl: var(--emu-common-line-heights-wide-xxxl);
  --emu-semantic-line-heights-wide-jumbo: var(--emu-common-line-heights-wide-jumbo);
  --emu-semantic-font-weight-600: var(--emu-common-font-weight-600);
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl)/var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl)/var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl)/var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large)/var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium)/var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-body-regular)/var(--emu-semantic-line-heights-narrow-jumbo) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl)/var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl)/var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl)/var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large)/var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large)/var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-body-medium)/var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-body);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1600px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-border-width-none: var(--emu-common-border-width-none);
  --emu-semantic-font-families-body: var(--emu-common-font-families-sans);
  --emu-semantic-font-families-heading: var(--emu-common-font-families-serif);

  /**
  * @theme botox-cosmetic-consumer-site-theme
  * @category component tokens
  */
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-body-small)/var(--emu-common-line-heights-narrow-jumbo) var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-body-small)/var(--emu-common-line-heights-narrow-jumbo) var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-common-colors-light-grey-200);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-common-border-radius-small);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-common-border-radius-small);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-common-border-radius-small);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-common-border-radius-small);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-padding-narrow: 13px 12px;
  --emu-component-actions-button-padding-wide: 13px 12px;
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-medium);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-medium);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-medium);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-medium);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-hover-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-focus-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-hover-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-active-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-component-actions-button-color-background-secondary-outline-focus-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-component-actions-button-color-background-secondary-outline-focus-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-common-colors-light-grey-200);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-hover-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-dark-grey-100);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-common-colors-dark-grey-100);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-common-colors-brand-grey-700);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-container-max-width: 1600px;
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-text-padding-top-narrow: 12px;
  --emu-component-lists-navigation-text-padding-top-wide: 12px;
  --emu-component-lists-navigation-text-padding-right-narrow: 10px;
  --emu-component-lists-navigation-text-padding-right-wide: 10px;
  --emu-component-lists-navigation-text-padding-bottom-narrow: 12px;
  --emu-component-lists-navigation-text-padding-bottom-wide: 12px;
  --emu-component-lists-navigation-text-padding-left-narrow: 12px;
  --emu-component-lists-navigation-text-padding-left-wide: 12px;
  --emu-component-lists-accordion-margin-narrow: 0px;
  --emu-component-lists-accordion-margin-wide: 0px;
  --emu-component-lists-accordion-item-header-padding-narrow: 18px 0px 24px;
  --emu-component-lists-accordion-item-header-padding-wide: 18px 0px 24px;
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: 32px;
  --emu-component-lists-accordion-item-header-icon-size-height-wide: 32px;
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: 40px;
  --emu-component-lists-accordion-item-header-icon-size-width-wide: 40px;
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: -2px 0px 0px 24px;
  --emu-component-lists-accordion-item-header-icon-margin-wide: -4px 0px 0px 36px;
  --emu-component-lists-accordion-item-body-padding-narrow: 0px 0px 16px 0px;
  --emu-component-lists-accordion-item-body-padding-wide: 0px 0px 32px 0px;
  --emu-component-containers-carousel-padding-narrow: 57px 0px 24px 0px;
  --emu-component-containers-carousel-padding-wide: 80px 0px 56px 0px;
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: #959292;
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-dots-size-narrow: 10px;
  --emu-component-containers-carousel-indicators-dots-size-wide: 10px;
  --emu-component-containers-carousel-indicators-dots-gap-narrow: 0px;
  --emu-component-containers-carousel-indicators-dots-gap-wide: 0px;
  --emu-component-containers-carousel-indicators-dots-padding-narrow: 0px;
  --emu-component-containers-carousel-indicators-dots-padding-wide: 0px;
  --emu-component-containers-carousel-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-common-spacing-brand-xs);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-common-spacing-brand-xs);
  --emu-component-containers-carousel-controls-color-background-light: #ffffff99;
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-carousel-color-border-light: var(--emu-semantic-colors-none);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-gap-horizontal-narrow: 0px;
  --emu-component-containers-tabs-gap-horizontal-wide: 0px;
  --emu-component-containers-tabs-gap-vertical-narrow: 0px;
  --emu-component-containers-tabs-gap-vertical-wide: 0px;
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: 6px;
  --emu-component-containers-tabs-navigation-item-padding-top-wide: 6px;
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: 9px;
  --emu-component-containers-tabs-navigation-item-padding-right-wide: 9px;
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: 6px;
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: 6px;
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: 9px;
  --emu-component-containers-tabs-navigation-item-padding-left-wide: 9px;
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: 0px;
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: 0px;
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: 4px;
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: 0px;
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-common-colors-black);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: 0px;
  --emu-component-containers-tabs-content-padding-top-wide: 0px;
  --emu-component-containers-tabs-content-padding-right-narrow: 0px;
  --emu-component-containers-tabs-content-padding-right-wide: 0px;
  --emu-component-containers-tabs-content-padding-bottom-narrow: 0px;
  --emu-component-containers-tabs-content-padding-bottom-wide: 0px;
  --emu-component-containers-tabs-content-padding-left-narrow: 0px;
  --emu-component-containers-tabs-content-padding-left-wide: 0px;
  --emu-component-containers-tabs-content-gap-horizontal-narrow: 0px;
  --emu-component-containers-tabs-content-gap-horizontal-wide: 0px;
  --emu-component-containers-tabs-content-gap-vertical-narrow: 0px;
  --emu-component-containers-tabs-content-gap-vertical-wide: 0px;
  --emu-component-containers-tabs-content-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-brand-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-brand-medium);
  --emu-component-containers-isi-header-color-background-light: rgba(255, 255, 255, 0.8);
  --emu-component-containers-isi-body-padding-top-narrow: 45px;
  --emu-component-containers-isi-body-padding-top-wide: 76px;
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-brand-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: 21px;
  --emu-component-containers-isi-body-padding-bottom-wide: 58px;
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-brand-medium);
  --emu-component-cards-teaser-padding-narrow: 24px 16px 24px;
  --emu-component-cards-teaser-padding-wide: 24px 16px 24px;
  --emu-component-cards-teaser-disclaimer-padding-narrow: 0px;
  --emu-component-cards-teaser-disclaimer-padding-wide: 0px;
  --emu-component-cards-teaser-description-padding-narrow: 0px;
  --emu-component-cards-teaser-description-padding-wide: 0px;
  --emu-component-cards-teaser-title-max-width: 100%;
  --emu-component-cards-teaser-title-margin-narrow: 0px;
  --emu-component-cards-teaser-title-margin-wide: 0px;
  --emu-component-layers-modal-scrim-color-background: rgba(0,0,0,0.80);
  --emu-component-layers-modal-body-sizing-width: 100%;
  --emu-component-layers-modal-body-padding-top-narrow: 0px;
  --emu-component-layers-modal-body-padding-top-wide: 0px;
  --emu-component-layers-modal-body-padding-right-narrow: 0px;
  --emu-component-layers-modal-body-padding-right-wide: 0px;
  --emu-component-layers-modal-body-padding-bottom-narrow: 0px;
  --emu-component-layers-modal-body-padding-bottom-wide: 0px;
  --emu-component-layers-modal-body-padding-left-narrow: 0px;
  --emu-component-layers-modal-body-padding-left-wide: 0px;
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-common-border-width-none);
  --emu-component-layers-modal-actions-close-padding-top: 0px;
  --emu-component-layers-modal-actions-close-padding-right: 0px;
  --emu-component-layers-modal-actions-close-padding-bottom: 0px;
  --emu-component-layers-modal-actions-close-padding-left: 0px;
  --emu-component-layers-cookie-consent-banner-max-width: 665px;
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 16px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 16px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 16px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 16px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 14px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 14px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 16px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 16px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-captions-regular)/var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-body);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-captions-regular)/var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-body);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large)/var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 15px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: var(--emu-common-border-width-thin);
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: var(--emu-semantic-outline-width-thin);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-common-border-radius-small);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-common-border-radius-small);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-common-border-radius-small);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-common-border-radius-small);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-image-map-buttons-size-height: 32px;
  --emu-component-ingredients-image-map-buttons-size-width: 32px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-common-colors-transparent);
}