div:has(> .page-banner),
.js-has-page-banner {
  float: none !important; // To override default styles from AAAEM Common. Cannot be overridden with specificity, unless class names given by AEM are used
}

.page-banner {
  opacity: 0;
  max-height: 0;
  font-size: var(--emu-common-font-sizes-captions-medium);
  transition: all var(--emu-common-other-time-transition-short) linear;
  overflow: hidden;
  pointer-events: none;
  position: relative;

  @include mq('large') {
    font-size: var(--emu-common-font-sizes-body-small);
  }

  @include mq('xx-large') {
    overflow: unset;
  }

  &.aaaem-container {
    max-width: none;
  }

  @include aem-editor-view {
    max-height: none !important; // to override styles added by JS
    opacity: 1;
    pointer-events: initial;
  }

  &::after,
  &::before {
    @include mq('xx-large') {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: calc(50vw - 100% / 2);
      background: inherit;
      height: 100%;
      transform: translateX(-100%);
    }
  }

  &::after {
    @include mq('xx-large') {
      left: auto;
      right: 0;
      transform: translateX(100%);
    }
  }

  > .container {
    padding-top: 9px;
    padding-right: 14px;
    padding-bottom: 7px;
    padding-left: 12px;

    @include mq('large') {
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }

  &--show {
    opacity: 1;
    pointer-events: unset;

    @include mq('large') {
    }
  }

  p,
  li {
    font-size: inherit;
    line-height: inherit;
  }

  &__inner {
    display: flex;
    align-items: center;

    > .container {
      &:first-child {
        flex: 1;
      }
    }
  }

  &__txt {
    padding-left: 20px;

    @include mq('large') {
      padding-left: var(--emu-common-spacing-medium);
    }

    p {
      position: relative;

      .emphasis {
        line-height: var(--emu-common-line-heights-narrow-xxxl);
        letter-spacing: 0.7px;
        margin-left: 6px;
        margin-right: 3px;

        @include mq('large') {
          letter-spacing: 0.98px;
          margin-left: 5px;
          margin-right: 3px;
        }
      }

      a {
        display: inline-flex;
      }
    }
  }

  &__cta {
    &-container {
      display: flex;
      gap: var(--emu-common-spacing-small);
    }
  }
}
