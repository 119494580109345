#science {
  .science {
    &__hero-feature {
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);

      .feature {
        &__img {
          margin-bottom: 18px;
          margin-left: 30px;

          @include mq('large') {
            margin-bottom: 78px;
            margin-left: var(--emu-common-spacing-none);
          }

          .cmp-image__image {
            min-height: 127px; //to match with design
            object-fit: cover;

            @include mq('large') {
              min-height: 146px; // to match with design
            }
          }
        }

        &__title {
          margin-bottom: 29px;

          @include mq('large') {
            margin-bottom: 27px;
          }

          .cmp-title__text {
            letter-spacing: -0.96px;

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-wide-xxxl);
              line-height: var(--emu-common-line-heights-narrow-small);
              letter-spacing: -0.5px;
            }

            > sup {
              top: 2px;
              left: -3px;

              @include mq('large') {
                font-size: 100%;
                top: 28px;
                left: -5px;
              }
            }
          }
        }

        &__txt {
          @include mq('large') {
            margin-bottom: 90px;
          }
        }
      }
    }

    &__vial-feature {
      padding-bottom: var(--emu-common-spacing-brand-large);

      @include mq('large') {
        padding-bottom: 160px;
      }

      .feature {
        &__img {
          margin-bottom: -8px; // to match with design

          @include mq('large') {
            margin-bottom: -17px; // to match with design
            margin-left: 30px;
          }
        }

        &__title {
          margin-bottom: 31px;
          padding-left: 36px;
          padding-right: 37px;

          @include mq('large') {
            max-width: 686px; // to match with design
            margin-bottom: 47px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          sup {
            top: 2px;

            @include mq('large') {
              top: 4px;
            }
          }
        }

        &__txt {
          @include mq('large') {
            margin-bottom: 26px;
          }
        }
      }
    }

    &__teaser {
      .content-container {
        padding-bottom: 26px;

        @include mq('large') {
          padding-top: 46px;
        }
      }

      .emu-teaser {
        &__title {
          margin-bottom: 12px;

          @include mq('large') {
            margin-bottom: 28px;
          }
        }

        &__description {
          @include mq('large') {
            width: 418px;
          }

          > p {
            font-size: var(--emu-common-font-sizes-body-regular);
            line-height: var(--emu-common-line-heights-narrow-jumbo);
          }
        }

        &__image {
          margin-top: 106px;
          margin-bottom: var(--emu-common-spacing-brand-xs);

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &__faq {
      padding-bottom: 82px;

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-none);
      }

      .faq {
        &__filter-cta-container {
          margin-top: var(--emu-common-spacing-medium);

          @include mq('large') {
            margin-top: 27px;
          }
        }

        &__toggler {
          margin-bottom: var(--emu-common-spacing-large);

          @include mq('large') {
            margin-bottom: 17px;
          }
        }

        &__section {
          &--left {
            padding-bottom: 14px;
          }

          &--right {
            @include mq('large') {
              padding-top: 55px;
            }
          }
        }

        &__accordion {
          .aaaem-accordion {
            &__button {
              @include mq('large') {
                align-items: center;
              }
            }

            &__icon {
              margin-left: 12px;

              @include mq('large') {
                margin-left: 38px;
                margin-top: -2px;
              }
            }

            &__panel {
              transform: translateY(-15px);

              @include mq('large') {
                transform: translateY(-7px);
              }

              .js-badger-accordion-panel-inner {
                padding-bottom: 17px;

                @include mq('large') {
                  padding-bottom: 25px;
                }
              }
            }

            &__header {
              @include mq('large') {
                padding-top: 24px;
              }
            }
          }
        }
      }
    }
  }

  .pre-footer {
    margin-bottom: -21px; // to match the design
    z-index: var(--emu-common-other-z-index-layer);
    position: relative;

    @include mq('large') {
      margin-bottom: -22px; // to match the design
    }
  }

  .aaaem-isi-container__isi-content > div {
    @include mq('large') {
      padding-bottom: 44px;
    }
  }
}
